import {
  add,
  Duration,
  endOfDay,
  endOfMonth as dateFnsEndOfMonth,
  format as dateFnsFormat,
  getDate,
  isEqual,
  parse as dateFnsParse,
  setDate,
  startOfDay,
  startOfMonth as dateFnsStartOfMonth,
  sub,
} from 'date-fns';

import { Lang } from '@sbiz/common';

import { WEEK_DAYS } from './constants';
import { DateRange, Locale, LOCALES } from './types';

export function formatDate(date: Date | string | null | undefined, format: string, lang?: Lang) {
  return date ? dateFnsFormat(date, format, lang && { locale: getLocale(lang) }) : '';
}

export function getDateInFuture(amount?: number, unit?: keyof Duration, refDate?: Date | string) {
  return add(refDate ?? new Date(), { [unit ?? 'days']: amount ?? 1 });
}

export function getDateInFutureDuration(duration: Duration, refDate?: Date | string) {
  return add(refDate ?? new Date(), duration);
}

export function getDateInPast(amount?: number, unit?: keyof Duration, refDate?: Date | string) {
  return sub(refDate ?? new Date(), { [unit ?? 'days']: amount ?? 1 });
}

export function getDateInPastDuration(duration: Duration, refDate?: Date | string) {
  return sub(refDate ?? new Date(), duration);
}

export function getDayOfMonth(date?: Date | string) {
  return getDate(date ?? new Date());
}

export function getDayOfWeek(index: number) {
  return WEEK_DAYS[index % WEEK_DAYS.length];
}

export function getTimestamps(date?: Date) {
  const timestamp = date ?? new Date();
  return { createdAt: timestamp, updatedAt: timestamp };
}

export function getLocale(lang: string) {
  const formattedLang = lang.substring(0, 2) as Locale;

  if (formattedLang in LOCALES) {
    return LOCALES[formattedLang];
  }

  return LOCALES.fr;
}

export function getDateRange(config: { end?: Date; start: Date }): DateRange;
export function getDateRange(config: { duration: Duration; end?: Date }): DateRange;
export function getDateRange(config: { duration: Duration; start: Date }): DateRange;
export function getDateRange(
  config:
    | { duration?: never; end?: Date; start: Date }
    | { duration: Duration; end?: Date; start?: never }
    | { duration: Duration; end?: never; start: Date },
) {
  if (config.duration) {
    if (config.start) {
      return {
        end: endOfDay(add(config.start, config.duration)),
        start: startOfDay(config.start),
      };
    }

    const end = config.end ?? new Date();
    return { end: endOfDay(end), start: startOfDay(sub(end, config.duration)) };
  }

  return { end: endOfDay(config.end ?? new Date()), start: startOfDay(config.start) };
}

export function getEndOfMonth(date?: Date | number | string) {
  return dateFnsEndOfMonth(date ?? new Date());
}

export function getStartOfMonth(date?: Date | number | string) {
  return dateFnsStartOfMonth(date ?? new Date());
}

export function getStartOfNextDay(date: Date | string) {
  return startOfDay(add(date, { days: 1 }));
}

export function isEndOfMonth(date?: Date | string) {
  return isEqual(date ?? new Date(), getEndOfMonth(date));
}

export function isStartOfMonth(date?: Date | string) {
  return isEqual(date ?? new Date(), getStartOfMonth(date));
}

export function parseDate(dateString: string, format: string) {
  return dateFnsParse(dateString, format, new Date());
}

export function setDayOfMonth(day: number, date?: Date | string) {
  return setDate(date ?? new Date(), day);
}
