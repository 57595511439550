import { getTotal, isInSet, toCentimes } from '@sbiz/util-common';
import { capitalize } from '@sbiz/util-strings';

import { Order, PERMISSIONS_SCOPES, PermissionsScope } from './types';

const PERMISSIONS_SCOPE_SET = new Set(PERMISSIONS_SCOPES);
const ADMIN_PERMISSIONS_SCOPE_PREFIX = 'admin';

export function getAdminPermissionsScope(scope: PermissionsScope) {
  if (scope.startsWith(ADMIN_PERMISSIONS_SCOPE_PREFIX)) {
    return null;
  }

  const adminScope = `${ADMIN_PERMISSIONS_SCOPE_PREFIX}${capitalize(scope)}`;
  return isInSet(adminScope, PERMISSIONS_SCOPE_SET) ? adminScope : null;
}

export function getOrderBenefitUse(order: Order) {
  const benefitUsage = getTotal(order.benefits);

  if (benefitUsage) {
    const totalOrder = getTotalOrder(order);
    return Math.min(benefitUsage, totalOrder);
  }

  return benefitUsage;
}

export function getTotalOrder({ tips, totalOrder, totalOrderWithTips }: Order) {
  const driverTips = toCentimes(tips?.driver?.amount);
  const total = toCentimes(totalOrder);
  const totalWithTips = toCentimes(totalOrderWithTips);

  if (driverTips && totalWithTips === total + driverTips) {
    return totalWithTips;
  }

  return total;
}
