import { Duration } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { StorageType, getStorageItem, removeStorageItem, setStorageItem } from '@sbiz/util-browser';
import { isPlainObject } from '@sbiz/util-common';
import { getDateInFutureDuration } from '@sbiz/util-dates';

type StorageItem<T extends StoredValue> = { _expiresAt?: number; _value: T | undefined };
type StoredValue = boolean | number | null | object | string | StoredValue[];

export type StorageOptions = { duration?: Duration; type?: StorageType };

export function useStorageItem<T extends StoredValue>(key: string | null, options?: StorageOptions) {
  const type = options?.type ?? 'session';

  const getItem = useCallback((): StorageItem<T> => {
    if (key) {
      const item = getStorageItem<StorageItem<T>>(key, type);

      if (item !== undefined) {
        // For retrocompatibility
        const isCurrentFormat = isPlainObject(item) && '_value' in item;
        if (!isCurrentFormat) {
          return { _value: item as T };
        }

        if (item._expiresAt === undefined || item._expiresAt > Date.now()) {
          return item;
        }
      }
    }

    return { _value: undefined };
  }, [key, type]);

  const scheduleRefresh = useCallback(
    (expiresAt: number) => {
      const expiresIn = expiresAt - Date.now();

      setTimeout(() => {
        const freshItem = getItem();

        setItem(freshItem);

        if (typeof freshItem._expiresAt === 'number') {
          scheduleRefresh(freshItem._expiresAt);
        }
      }, expiresIn);
    },
    [getItem],
  );

  const [item, setItem] = useState<StorageItem<T>>(() => {
    const freshItem = getItem();

    if (typeof freshItem._expiresAt === 'number') {
      scheduleRefresh(freshItem._expiresAt);
    }

    return freshItem;
  });

  const getValue = useCallback(() => getItem()._value, [getItem]);

  const setValue = useCallback(
    (newValue: T | ((currentValue: T | undefined) => T | undefined) | undefined) => {
      if (!key) {
        return;
      }

      setItem(() => {
        const currentItem = getItem();
        const value = typeof newValue === 'function' ? newValue(currentItem?._value) : newValue;
        const item: StorageItem<T> = { _value: value };

        if (item._value === undefined) {
          removeStorageItem(key, type);
          return item;
        }

        if (options?.duration) {
          const expiresAt = getDateInFutureDuration(options.duration).valueOf();
          item._expiresAt = expiresAt;
          scheduleRefresh(expiresAt);
        }

        setStorageItem(key, item, type);
        return item;
      });
    },
    [getItem, key, options?.duration, scheduleRefresh, type],
  );

  return useMemo(() => ({ getValue, setValue, value: item._value }), [getValue, item._value, setValue]);
}
